import React, { useState } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import datasciencePDF from '../../images/curriculum-Data-Science.pdf'
import fullstackPDF from '../../images/curriculum-Full-Stack-Development.pdf'
import devopsPDF from '../../images/curriculum-Devops.pdf'
import devopsPDF1 from '../../images/30_Hours_Module_DevOPS.pdf'
import iotPDF from '../../images/Embedded-IoT.pdf'

const CurriculumForm = (props) => {

    const BACKEND_URL = process.env.REACT_APP_BACKEND_ADDRESS

    const getRedirectpath = (courseId)=>{
        if(courseId === 1){
            return devopsPDF
        } else if(courseId === 2){
            return datasciencePDF
        } else if(courseId === 3){
            return fullstackPDF
        } else if(courseId === 4){
            return iotPDF
        }else if(courseId === 5){
            return devopsPDF1
        }
    }

    const [inpval, setinpval] = useState({
        fname: "",
        email: "",
        phone: ""
    })

    const setvalue = async (e)=>{
        const {name, value} = e.target

        setinpval(()=>{
            return{
                ...inpval,
                [name]: value
            }
        })
    }

    // console.log(url)
    const dataSubmit = async(e)=>{
        e.preventDefault();
        const {fname, email, phone} = inpval;

        if(!fname || !email || !phone){
            toast.error("Please Fill all fields")
        }

        try {
            const data = await fetch(`${BACKEND_URL}/curriculumform`, {
                method: "POST",
                headers:{
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    fname, email, phone
                })
            })

            const res = await data.json();
            
            if(res.status === 201){
                setinpval({
                    fname: "",
                    email: "",
                    phone: ""
                })
                toast.success("Registration Successfully")
                // navigate(getRedirectpath(props.courseId))
                window.location.href = getRedirectpath(props.courseId);
                // console.log(getRedirectpath(props.courseId))
            }
            console.log(res.status)
            if(res.status === 409){
                toast.error("This Email allready register")
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className={`PopUpmodal CurriculumModel`} id="MainParentPopup">
                <div className="modalPopUpcontent">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">
                            Enter your details to download the program syllabus
                        </h4>
                        <button
                            type="button"
                            className="close close-button"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={props.closeCurriculum}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="w3l-contacts-12 home_form">
                            <div className="contacts12-main">
                                <form className="main-input">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        name="fname"
                                        value={inpval.fname}
                                        onChange={setvalue}
                                        required
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        value={inpval.email}
                                        onChange={setvalue}
                                        required
                                    />
                                    <input
                                        type="text"
                                        placeholder="Number"
                                        name="phone"
                                        value={inpval.phone}
                                        onChange={setvalue}
                                        required
                                    />
                                    <div>
                                        {/* <NavLink onClick={dataSubmit} to={getRedirectpath(props.courseId)} className="YellowBTN">
                                            Download Now
                                        </NavLink> */}
                                        <button type="submit" onClick={dataSubmit} className="YellowBTN">Download Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}

export default CurriculumForm;
import React from 'react';

const LinuxTestimonial = () => {
  return (
    <>
        <section className='LinuxTestiSec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-12'>
                        <div className='LinuxTestiDiv'>
                            <h3>Testimonial</h3>
                            <p>Feedbacks : Voices from Our Community</p>
                            <div class="owl-carousel LinuxTestiOwlCarousel owl-theme">
                                <div class="item">
                                    <div className="LinuxTestPeople">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore labore necessitatibus doloremque, deserunt quod dolorum dolores alias molestias quaerat consequuntur!</p>
                                        <ul>
                                            <li>
                                                <img src="https://media.gettyimages.com/id/687830554/photo/portrait-of-a-happy-latin-american-teacher-at-the-school.jpg?s=612x612&w=0&k=20&c=JACpTuv10ZV6OF-xMi4vYHzeCZV9hoImPII11CjHMOU=" alt="testimonial" />
                                            </li>
                                            <li>
                                                <h3>Anushka</h3>
                                                <p>Software Developer</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="item">
                                    <div className="LinuxTestPeople">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore labore necessitatibus doloremque, deserunt quod dolorum dolores alias molestias quaerat consequuntur!</p>
                                        <ul>
                                            <li>
                                                <img src="https://media.gettyimages.com/id/687830554/photo/portrait-of-a-happy-latin-american-teacher-at-the-school.jpg?s=612x612&w=0&k=20&c=JACpTuv10ZV6OF-xMi4vYHzeCZV9hoImPII11CjHMOU=" alt="testimonial" />
                                            </li>
                                            <li>
                                                <h3>Anushka</h3>
                                                <p>Software Developer</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="item">
                                    <div className="LinuxTestPeople">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore labore necessitatibus doloremque, deserunt quod dolorum dolores alias molestias quaerat consequuntur!</p>
                                        <ul>
                                            <li>
                                                <img src="https://media.gettyimages.com/id/687830554/photo/portrait-of-a-happy-latin-american-teacher-at-the-school.jpg?s=612x612&w=0&k=20&c=JACpTuv10ZV6OF-xMi4vYHzeCZV9hoImPII11CjHMOU=" alt="testimonial" />
                                            </li>
                                            <li>
                                                <h3>Anushka</h3>
                                                <p>Software Developer</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="item">
                                    <div className="LinuxTestPeople">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore labore necessitatibus doloremque, deserunt quod dolorum dolores alias molestias quaerat consequuntur!</p>
                                        <ul>
                                            <li>
                                                <img src="https://media.gettyimages.com/id/687830554/photo/portrait-of-a-happy-latin-american-teacher-at-the-school.jpg?s=612x612&w=0&k=20&c=JACpTuv10ZV6OF-xMi4vYHzeCZV9hoImPII11CjHMOU=" alt="testimonial" />
                                            </li>
                                            <li>
                                                <h3>Anushka</h3>
                                                <p>Software Developer</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default LinuxTestimonial
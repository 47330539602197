import React from 'react';
import AkashSir from '../../images/Akash_sir_photo.png'
import AshuSir from '../../images/AshuSirImg.png'
import { Link, NavLink } from 'react-router-dom';

const LinuxInstructor = () => {
  return (
    <>
        <section className='LinuxInsSec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 class="WhiteHeading mb-4">The Guide Leading Your Journey</h2>
                    </div>
                </div>
                <div className='row align-items-center gy-4'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1'>
                        <div className='TeacherContent AshuSirLinux'>
                            <h3>Ashutosh Singh</h3>
                            <h6>CTO & Corporate Trainer</h6>
                            <p>Ashutoshh's innovative vision, technical expertise, and unwavering dedication have played a pivotal role in driving the company's business development and growth. His ability to navigate the ever-evolving tech landscape has not only kept the company at the forefront but has also inspired the entire team. <span>His commitment to knowledge-sharing and skill development has empowered the team to excel. His training programs have not only equipped individuals with essential skills but have also contributed significantly to the overall professional growth of the organization.</span></p>
                            <ul>
                                <li>
                                    <Link to={"https://github.com/redashu"} target="_blank">
                                        <i class="fa-brands fa-github"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://www.linkedin.com/in/ashutoshh/"} target="_blank">
                                        <i class="fa-brands fa-linkedin"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className='ImgDiv'>
                            <img src={AshuSir} alt='akash sir'/>
                        </div>
                    </div>
                </div>
                <div className='row align-items-center mt-lg-5 mt-2 gy-4'>
                    <div className='col-lg-6 col-12'>
                        <div className='ImgDiv'>
                            <img src={AkashSir} alt='akash sir'/>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <div className='TeacherContent'>
                            <h3>Aakash Gaur</h3>
                            <h6>Sr.Devops Instructor</h6>
                            <p>Aakash is having an experience of 7+ Years and is an expert at the forefront of cloud computing and DevOps methodologies, equipping our learners with the skills to navigate and optimize cloud environments efficiently. Our instructor not only possess in-depth knowledge of cloud platforms and DevOps practices but also have a passion for teaching. He guide our students in harnessing the power of cloud technologies, ensuring he is well-prepared to thrive in the fast-evolving world of IT and infrastructure management. <span>His Key expertise are - Linux , Cloud (like AWS, microsoft azure , GCP ), DevOps tools (like docker , kubernetes , jenkins , ansible , terraform ) , monitoring.</span></p>
                            <ul>
                                <li>
                                    <Link to={"https://github.com/gaur95"} target="_blank">
                                        <i class="fa-brands fa-github"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://www.linkedin.com/in/gaur95/"} target="_blank">
                                        <i class="fa-brands fa-linkedin"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default LinuxInstructor
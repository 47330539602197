import React, { useEffect, useState } from "react";
import FeesData from "./FeesData.json";
import { useForm } from "react-hook-form";
import axios from "axios";
import "../../RegisterSection.css";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";

const PaymentRegister = () => {
  // const couponCodes = [
  //   { name: "SUMMER40", value: 40 },
  //   { name: "4KPAYMENT", value: 10 },
  // ];

  const RajUniversities = [
    "Amity University",
    "Anand International College of Engineering",
    "ARKA JAIN UNIVERSITY",
    "Arya College of Engineering and IT",
    "Banasthali Vidyapith",
    "Bhartiya Institute of Engineering and Technology",
    "Birla Institute of Technology and Science",
    "B K Birla Institute of Engineering and Technology",
    "Biyani International Institute of Engineering and Technology for Girls",
    "Central University of Rajasthan",
    "Career Point University",
    "Dr KN Modi University",
    "Geetanjali Institute of Technical Studies",
    "Global Institute of Technology",
    "Government Engineering College",
    "Government Women Engineering College",
    "Gyan Vihar School of Engineering and Technology",
    "ICFAI University",
    "Jaipur Engineering College",
    "Jaipur Engineering College and Research Centre",
    "Jaipur National University",
    "Jagan Nath Gupta Institute of Engineering and Technology",
    "Jagannath University",
    "Jayoti Vidyapeeth Women's University",
    "JK Lakshmipat University",
    "JIET Institute of Design and Technology",
    "Jodhpur institute of engg.&tech.",
    "Jodhpur National University",
    "Laxmi Devi Institute of Engineering and Technology",
    "Lords University",
    "Maharishi Arvind College of Engineering and Research Centre",
    "Malaviya National Institute of Technology",
    "Manipal University",
    "MLV Government Textile and Engineering College",
    "Modern Institute of Technology and Research Centre",
    "Mody University of Science and Technology",
    "NIMS University",
    "Pacific Institute of Technology",
    "Pratap Institute of Technology and Science",
    "Pratap University",
    "Raja balwant singh engineering technical campus",
    "Rajasthan College of Engineering for Women",
    "Rajasthan Institute of Engineering and Technology",
    "Rajasthan Technical University",
    "Regional College for Education Research and Technology",
    "RNB Global University",
    "Shekhawati Institute of Engineering and Technology",
    "Shree Digamber Institute of Technology",
    "Shankara Institute of Technology",
    "Sobhasaria Group of Institutions",
    "Sri Balaji College of Engineering and Technology",
    "Sir Padampat Singhania University",
    "Swami Keshvanand Institute of Technology, Management and Gramothan",
    "Techno India NJR Institute of Technology",
    "University of Engineering and Management",
    "Vivekananda Global University",
    "Vivekananda Institute of Technology",
    "Vivekananda Institute of Technology East",
    "Other",
  ];
  const BACKEND_URL = process.env.REACT_APP_BACKEND_ADDRESS;
  const [step, setStep] = useState(1);
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const { register, formState, handleSubmit, resetField, reset } = useForm();
  const { errors } = formState;
  const [college, setCollege] = useState(null);
  const [feesValue, setFeesValue] = useState(null);
  const [courseName, setCourseName] = useState(null);
  const [classMode, setClassMode] = useState(null);
  const [couponValue, setCouponValue] = useState(null);
  const [couponError, setCouponError] = useState(null);
  const [discountFees, setDiscountFees] = useState(null);
  const navigate = useNavigate();
  const [showCoupon, setShowCoupon] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("register");

  useEffect(() => {
    {
      selectedTechnology &&
        classMode &&
        setFeesValue(
          FeesData.AllData.Technologies[selectedTechnology].Fees[classMode]
        );
    }
    // setDiscountFees(null);
    // setCouponValue(null);
    // resetField("couponcode");
  }, [classMode, selectedTechnology]);

  // useEffect(() => {
  //   if (
  //     selectedTechnology &&
  //     (selectedTechnology === "FrontendDevelopment" ||
  //       selectedTechnology === "LinuxCourse" ||
  //       selectedTechnology === "PythonCourse")
  //   ) {
  //     setShowCoupon(false);
  //   } else {
  //     setShowCoupon(true);
  //   }
  // }, [selectedTechnology]);

  function submitForm1(data) {
    const {
      fullName,
      email,
      collegeName,
      course,
      otherCollege,
      whatsappNumber,
      year,
    } = data;

    const formData = {
      fullName,
      email,
      collegeName,
      otherCollege,
      course,
      whatsappNumber,
      year,
    };

    axios
      .post(`${BACKEND_URL}/form1`, formData)
      .then(() => {
        setStep(2);
      })
      .catch((error) => console.log(error));
  }

  function submitForm2(data) {
    const {
      fullName,
      email,
      collegeName,
      otherCollege,
      course,
      whatsappNumber,
      year,
      comment,
      duration,
      knowingSource,
      mode,
      reference,
      startMonth,
      technology,
    } = data;

    const formData = {
      fullName,
      email,
      collegeName,
      otherCollege,
      course,
      whatsappNumber,
      year,
      comment,
      duration,
      knowingSource,
      mode,
      reference,
      startMonth,
      technology: FeesData.AllData.Technologies[technology].name,
    };

    axios
      .post(`${BACKEND_URL}/form2`, formData)
      .then(() => {
        setStep(3);
      })
      .catch((error) => console.log(error));
  }

  async function handlePayment(userData) {
    try {
      userData.collegeName =
        userData.collegeName === "Other"
          ? userData.otherCollege
          : userData.collegeName;

      userData.technology =
        FeesData.AllData.Technologies[userData.technology].name;

      const salt = await bcrypt.genSaltSync(10);

      const fee1 = await bcrypt.hash(String(799), salt);

      const fee2Value = 799 + calcRemainingFees(feesValue);
      const fee2 = await bcrypt.hash(String(fee2Value), salt);

      const {
        data: { order },
      } = await axios.post(`${BACKEND_URL}/checkout`, {
        registerFee:
          paymentMethod === "register"
            ? 799
            : // : discountFees
              // ? calcRemainingFees(discountFees) + 799
              799 + calcRemainingFees(feesValue),
      });

      const {
        data: { key },
      } = await axios.get(`${BACKEND_URL}/getRazorKey`);

      const options = {
        key: key,
        currency: "INR",
        name: userData.fullName,
        description: "Payment for the registration",
        order_id: order.id,
        // callback_url: `${BACKEND_URL}/paymentverify`,
        handler: async function (response) {
          try {
            await axios.post(`${BACKEND_URL}/paymentverify`, {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              userData,
              couponCode: couponValue,
              paymentMethod: paymentMethod,
              hashAmount: paymentMethod === "register" ? fee1 : fee2,
              totalFees:
                // discountFees ? calcRemainingFees(discountFees) + 799 :
                calcRemainingFees(feesValue) + 799,
              remainingFees:
                paymentMethod === "register"
                  ? // discountFees ? calcRemainingFees(discountFees) :
                    calcRemainingFees(feesValue)
                  : 0,
            });

            navigate("/payment-success");
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          } catch (error) {
            console.log(error);
          }
        },
        theme: {
          color: "#FF9416",
        },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.log(error);
    }
  }

  // function applyCoupon(e) {
  //   e.preventDefault();
  //   const findCoupon = couponCodes.find((code) => code.name === couponValue);
  //   if (findCoupon) {
  //     if (findCoupon === "4KPAYMENT") return;
  //     const discount = Math.floor((findCoupon.value / 100) * feesValue);
  //     setDiscountFees(feesValue - discount);
  //     setCouponError(null);
  //   } else {
  //     setCouponError("Invalid Coupon Code");
  //     setCouponValue(null);
  //     setDiscountFees(null);
  //   }
  // }

  const handleTechnologySelect = (e) => {
    setSelectedTechnology(e.target.value);
    resetField("startMonth");
    resetField("duration");
  };

  const TrainingDurationSelect = (e) => {
    setSelectedDuration(e.target.value);
  };

  function calcRemainingFees(fees) {
    // if (couponValue === "4KPAYMENT") {
    //   return 3201;
    // } else {
    const gst = Math.floor(fees * 0.18);
    const totalFees = fees + gst;
    const remaining = totalFees - 799;
    return remaining;
    // }
  }

  return (
    <>
      <section className="main-section" id="register-section">
        <div className="sub-main-section">
          <div>
            <div className="heading-div">
              <h2 className="register-heading">Registration</h2>
              <div className="form-nav-div">
                {[1, 2, 3].map((num) => (
                  <div
                    key={num}
                    className="form-nav-num"
                    style={{ backgroundColor: step === num ? "#FF9416" : "" }}
                  >
                    <span>{num}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="RegisterColumn">
                <div className="flex flex-col gap-[2rem]">
                  {step === 1 && (
                    <form
                      className="form-div sm:grid-cols-2"
                      onSubmit={handleSubmit(submitForm1)}
                    >
                      <div className="form-sub-div">
                        <div className="form-row">
                          <label htmlFor="fullName" className="form-label">
                            Full Name
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            name="fullName"
                            id="fullName"
                            placeholder="Enter Full Name"
                            {...register("fullName", {
                              required: "This Field is Required",
                            })}
                          />
                          <p className="form-error">
                            {errors?.fullName?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter Your Email"
                            {...register("email", {
                              required: "This Field is Required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid email address",
                              },
                            })}
                          />
                          <p className="form-error">{errors?.email?.message}</p>
                        </div>
                        <div className="form-row">
                          <label
                            htmlFor="whatsappNumber"
                            className="form-label"
                          >
                            WhatsApp No.
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            id="whatsappNumber"
                            name="whatsappNumber"
                            placeholder="Enter Your WhatsApp Number"
                            {...register("whatsappNumber", {
                              required: "This Field is Required",
                              minLength: {
                                value: 10,
                                message: "Please enter valid a phone number.",
                              },
                            })}
                          />
                          <p className="form-error">
                            {errors?.whatsappNumber?.message}
                          </p>
                        </div>
                        {college === "Other" && (
                          <div className="form-row">
                            <label
                              htmlFor="otherCollege"
                              className="form-label"
                            >
                              Mention College Name
                            </label>
                            <input
                              className="form-input"
                              type="text"
                              id="otherCollege"
                              name="otherCollege"
                              placeholder="Enter Your College Name"
                              {...register("otherCollege", {
                                required: "This Field is Required",
                              })}
                            />
                            <p className="form-error">
                              {errors?.otherCollege?.message}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="form-sub-div">
                        <div className="form-row">
                          <label className="form-label" htmlFor="collegeName">
                            College Name
                          </label>
                          <select
                            name="collegeName"
                            id="collegeName"
                            className="form-select-input"
                            {...register("collegeName", {
                              required: "This Field is Required",
                            })}
                            onChange={(e) => setCollege(e.target.value)}
                          >
                            <option value="" disabled selected>
                              Select College
                            </option>
                            {RajUniversities.map((college, index) => (
                              <option key={index} value={college}>
                                {college}
                              </option>
                            ))}
                          </select>
                          <p className="form-error">
                            {errors?.collegeName?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label htmlFor="course" className="form-label">
                            Course
                          </label>
                          <select
                            id="course"
                            name="course"
                            autoComplete="course"
                            className="form-select-input"
                            {...register("course", {
                              required: "This Field is Required",
                            })}
                            onChange={(e) => setCourseName(e.target.value)}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            <option value="Bachelor of Technology">
                              B.Tech
                            </option>
                            <option value="Bachelor of Computer Applications">
                              BCA
                            </option>
                            <option value="Master of Technology">M.Tech</option>
                            <option value="Master of Computer Applications">
                              MCA
                            </option>
                          </select>
                          <p className="form-error">
                            {errors?.course?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label htmlFor="year" className="form-label">
                            Year of Study
                          </label>
                          <select
                            id="year"
                            name="year"
                            autoComplete="year"
                            className="form-select-input"
                            {...register("year", {
                              required: "This Field is Required",
                            })}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            <option value="1st">1st</option>
                            <option value="2nd">2nd</option>
                            {(courseName === "Bachelor of Technology" ||
                              courseName ===
                                "Bachelor of Computer Applications") && (
                              <option value="3rd">3rd</option>
                            )}
                            {courseName === "Bachelor of Technology" && (
                              <option value="4th">4th</option>
                            )}
                          </select>
                          <p className="form-error">{errors?.year?.message}</p>
                        </div>
                      </div>
                      <div>
                        <button className="form-submit-btn">Next</button>
                      </div>
                    </form>
                  )}
                  {step === 2 && (
                    <form
                      className="form-div"
                      onSubmit={handleSubmit(submitForm2)}
                    >
                      <div className="form-sub-div">
                        <div className="form-row">
                          <label className="form-label" htmlFor="technology">
                            Select Technology
                          </label>
                          <select
                            className="form-select-input"
                            name="technology"
                            id="technology"
                            {...register("technology", {
                              required: "This field is required",
                            })}
                            onChange={handleTechnologySelect}
                          >
                            <option value="" selected>
                              Select Technology
                            </option>
                            {Object.keys(FeesData.AllData.Technologies).map(
                              (technology) => (
                                <option key={technology} value={technology}>
                                  {
                                    FeesData.AllData.Technologies[technology]
                                      .name
                                  }
                                </option>
                              )
                            )}
                          </select>
                          <p className="form-error">
                            {errors?.technology?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label className="form-label" htmlFor="mode">
                            Training Mode
                          </label>
                          <select
                            className="form-select-input"
                            name="mode"
                            id="mode"
                            {...register("mode", {
                              required: "This field is required.",
                            })}
                            onChange={(e) => setClassMode(e.target.value)}
                          >
                            <option value="" selected>
                              Select Training Mode
                            </option>
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                          </select>
                          <p className="form-error">{errors?.mode?.message}</p>
                        </div>
                        <div className="form-row">
                          <label className="form-label" htmlFor="startMonth">
                            Training Starting From
                          </label>
                          <select
                            className="form-select-input"
                            name="startMonth"
                            id="startMonth"
                            {...register("startMonth", {
                              required: "This field is required",
                            })}
                          >
                            <option value="" selected>
                              Select Month
                            </option>
                            {selectedTechnology &&
                              FeesData.AllData.Technologies[
                                selectedTechnology
                              ].TrainingStart.map((startMonth) => {
                                return (
                                  <option value={startMonth}>
                                    {startMonth}
                                  </option>
                                );
                              })}
                          </select>
                          <p className="form-error">
                            {errors?.startMonth?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label className="form-label" htmlFor="duration">
                            Training Duration
                          </label>
                          <select
                            className="form-select-input"
                            name="duration"
                            id="duration"
                            {...register("duration", {
                              required: "This field is required",
                            })}
                            onChange={TrainingDurationSelect}
                          >
                            <option value="" selected>
                              Select Duration
                            </option>
                            {selectedTechnology &&
                              FeesData.AllData.Technologies[
                                selectedTechnology
                              ].TrainingDuration.map((trainingDuration) => {
                                return (
                                  <option value={trainingDuration}>
                                    {trainingDuration} Days
                                  </option>
                                );
                              })}
                          </select>
                          <p className="form-error">
                            {errors?.duration?.message}
                          </p>
                        </div>
                      </div>
                      <div className="form-sub-div">
                        <div className="form-row">
                          <label className="form-label" htmlFor="knowingSource">
                            How Did You Hear About us?
                          </label>
                          <select
                            className="form-select-input"
                            name="knowingSource"
                            id="knowingSource"
                            {...register("knowingSource", {
                              required: "This field is required",
                            })}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            <option value="Friends">Friends</option>
                            <option value="SocialMedia">Social Media</option>
                            <option value="Collage">College</option>
                            <option value="Other">Other</option>
                          </select>
                          <p className="form-error">
                            {errors?.knowingSource?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label className="form-label" htmlFor="comment">
                            Any Comment
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            id="comment"
                            name="comment"
                            placeholder="Comment"
                            {...register("comment")}
                          />
                          <p className="form-error">
                            {errors?.comment?.message}
                          </p>
                        </div>
                        <div className="form-row">
                          <label className="form-label" htmlFor="reference">
                            Reference Name
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            id="reference"
                            name="reference"
                            placeholder="Enter Reference Name"
                            {...register("reference")}
                          />
                          <p className="form-error">
                            {errors?.reference?.message}
                          </p>
                        </div>

                        {selectedTechnology && classMode && (
                          <div>
                            {/* <div className="bubble-msg">
                              <p>
                                Register Now and Get 40% Discount with Coupon
                                Code SUMMER40, Only Few Seats are Left
                              </p>
                            </div> */}
                            <div className="coupon-section">
                              <div className="form-fees">
                                {/* {discountFees ? (
                                  <span>
                                    <p>Total Fees : {feesValue} Rs + 18% GST</p>
                                    <p>Regsitration Fees : 799 Rs</p>
                                    <p>
                                      Remaining Fees :{" "}
                                      {calcRemainingFees(discountFees)} Rs
                                    </p>
                                  </span>
                                ) : ( */}
                                <span>
                                  <p>Total Fees : {feesValue} Rs + 18% GST</p>
                                  <p>Regsitration Fees : 799 Rs</p>
                                  <p>
                                    Remaining Fees :{" "}
                                    {calcRemainingFees(feesValue)} Rs
                                  </p>
                                </span>
                                {/* )} */}
                              </div>
                              {/* <div>
                                {showCoupon && (
                                  <div className="coupon-code">
                                    <label
                                      className="form-label"
                                      htmlFor="reference"
                                    >
                                      Coupon Code
                                    </label>
                                    <input
                                      className="form-input"
                                      type="text"
                                      id="couponcode"
                                      name="couponcode"
                                      value={couponValue}
                                      placeholder="Enter Coupon Name"
                                      {...register("couponcode")}
                                      onChange={(e) =>
                                        setCouponValue(e.target.value)
                                      }
                                    />
                                    {couponError && (
                                      <p className="form-error">
                                        {couponError}
                                      </p>
                                    )}
                                    <button
                                      className="form-submit-btn"
                                      onClick={applyCoupon}
                                    >
                                      Apply Coupon
                                    </button>
                                  </div>
                                )}
                              </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-btn-div">
                        <button
                          className="form-submit-btn"
                          onClick={() => setStep(1)}
                        >
                          Back
                        </button>
                        <button className="form-submit-btn">Next</button>
                      </div>
                    </form>
                  )}
                  {step === 3 && (
                    <div className="payment-form-div">
                      <form
                        className="payment-form"
                        onSubmit={handleSubmit(handlePayment)}
                      >
                        <div className="completePayment">
                          <div className="paymentHeader">
                            <img src="/favicon.ico" />
                            <h5>{`${new Date().getDate()} / ${
                              new Date().getMonth() + 1
                            } / ${new Date().getFullYear()}`}</h5>
                          </div>
                          <h2 className="payment-form-heading">Registration</h2>
                          <div className="RegistrationAmount">
                            <h6>Registration Fees</h6>
                            <b>799.00 Rs</b>
                          </div>
                          <div className="RegistrationAmount">
                            <h6>Remaining Fees</h6>
                            <b>
                              {/* {discountFees
                                ? calcRemainingFees(discountFees) :  */}
                              {calcRemainingFees(feesValue)}
                              {/* } */} Rs
                            </b>
                          </div>
                          <div className="RegistrationAmount">
                            <h6>GST</h6>
                            <b>18%</b>
                          </div>
                          <div className="RegistrationAmount">
                            <h6>Total Fees</h6>
                            <b>
                              <mark>
                                {calcRemainingFees(feesValue) + 799}
                                {/* {discountFees
                                  ? calcRemainingFees(discountFees) + 799
                                  : calcRemainingFees(feesValue) + 799}{" "} */}
                                Rs
                              </mark>
                            </b>
                          </div>
                          <div className="form-btn-div">
                            <button
                              className="form-submit-btn"
                              onClick={() => setStep(2)}
                            >
                              Back
                            </button>
                            <button
                              className="form-submit-btn"
                              onClick={() => setPaymentMethod("register")}
                            >
                              Register Only
                            </button>
                            <button
                              className="form-submit-btn"
                              onClick={() => setPaymentMethod("complete")}
                            >
                              Complete Payment
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster />
    </>
  );
};

export default PaymentRegister;

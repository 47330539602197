import React from 'react';
import {Link} from 'react-router-dom';
import LinuxBannerImg from '../../images/LinuxBanner.webp';
import './LinuxPage.css'

const LinuxBanner = () => {
    
  return (
    <>
        <section className='LinuxPageBanner'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-8 col-12'>
                        <div className='LinuxBannerCon'>
                            <h3>Unlocking Linux: An In-Depth Manual for Open-Source Proficiency.</h3>
                            <p>Embark on your journey to Linux expertise with our focused online course. Dive deep into essential concepts, polish your command-line proficiency, and elevate your system administration abilities. Take advantage of interactive labs and expert support, paving the way for a smooth and efficient path to mastery.</p>
                            <Link to={"/"} className='YellowBTN'>View Course</Link>
                        </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                        <div className='LinuxBanner'>
                            <img src={LinuxBannerImg} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default LinuxBanner
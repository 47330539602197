import React from 'react';
import FooterLogo from '../images/White_Logo.png';
import QrLogo from '../images/Upflairs-QR.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
        <footer className='Footermain'>
            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-2 col-md-6 col-12">
                        <div className="Footerlogo">
                            <img src={FooterLogo} alt="footer" />
                            <img className='QRLogo' src={QrLogo} alt="QR" />
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-12'>
                        <div className="footerLinks">
                            <h5>Company</h5>
                            <ul>
                                <li>
                                    <NavLink to={"/"}> About Us </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://summers.upflairs.com/campus"}> Campus Ambassador </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"http://upflairs.com/privacy-policy/"}> Privacy Policy </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"http://upflairs.com/terms-and-conditions/"}> Terms And Conditions </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/flair-stories/"}> Flairs Stories </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/careers/"}> Careers </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/blogs"}> Blog </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://rzp.io/i/XFSOE6ag"}> Make Payment </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6 col-12'>
                        <div className="footerLinks">
                            <h5>Products</h5>
                            <ul>
                                <li>
                                    <NavLink to={"https://upflairs.com/courses"}> Skill Based Learning </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://summers.upflairs.com/"}> Summer Internship & Training </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/in-campus-training"}> In Campus Training </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/hands-on-workshop"}> Hands-on Workshop </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12'>
                        <div className="footerLinks">
                            <h5>Popular Courses</h5>
                            <ul>
                                <li>
                                    <NavLink to={"https://upflairs.com/summer-training/machine-learning-ai"}> Data Science with Machine Learning & AI </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/summer-training/embedded-systems-iot"}> Embedded Systems & IoT </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/summer-training/python-django/"}> Full Stack Web Development </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"https://upflairs.com/courses/cloud-computing-devops-enigneering-in-jaipur"}> DevOps & Cloud Engineering </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-12'>
                        <div className="footerLinks FooterContact">
                            <h5>Contact Details</h5>
                            <ul>
                                <li>
                                    <NavLink>100/110ps. Rana Sanga Marg, Near Kumbha Marg Pratap Nagar Jaipur – IN (302033)</NavLink>
                                </li>
                                <li>
                                    <NavLink> Write to Us :info@upflairs.com </NavLink>
                                </li>
                                <li>
                                    <NavLink > Phone : +91 9251494002 </NavLink>
                                </li>
                                <li>
                                    <NavLink> Phone :+91 80059 32201 </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row footerCopyRight">
                    <div className="col-sm-6 col-12">
                        <div className="CopyRight">
                            <h6>© 2024 Upflairs Private Limited</h6>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <ul className="SocialMedia float-md-end">
                            <li>
                                <NavLink to={"https://www.facebook.com/profile.php?id=100071105106234"}><i class="fa-brands fa-facebook"></i></NavLink>
                            </li>
                            <li>
                                <NavLink to={"https://twitter.com/upflairs"}><i class="fa-brands fa-twitter"></i></NavLink>
                            </li>
                            <li>
                                <NavLink to={"https://www.instagram.com/upflairs_pvt_ltd/"}><i class="fa-brands fa-instagram"></i></NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <div className='WhatsAppDIV'>
            <NavLink to={"https://wa.me/916350417917"} className="WhatSappIcn" target="_blank">
                <i class="fa-brands fa-whatsapp"></i>
            </NavLink>
        </div>
    </>
  )
}

export default Footer
import React, { useState } from 'react'
import './campusBanner.css'
import CampusForm from './CampusForm'
const Banner = () => {

    const [IsModelOpen, setIsModelOpen] = useState(false)
    const FormOpenBTN = () =>{
        setIsModelOpen(true)
    }

    const closeModel = ()=>{
        setIsModelOpen(false)
    }
    return (
        <>
            <section className='BannerSection'>
                <div className="container">
                    <div className="row d-flex flex-column justify-content-center align-items-center BannerHeading">
                        <div className="col-lg-9 col-12">
                            <h3 className='text-white'>Be You. Be Unstoppable. Be a <span>Upflairs Ambassador</span></h3>
                            <h5 className='text-white'>Unleash your potential as an Upflairs Ambassador. Embrace resilience, determination, and community, igniting a journey of unstoppable growth and empowerment.</h5>
                        </div>
                        <div className="col-lg-2 ButtonBtn">
                            <button className="YellowBTN button-40 BannerBtn" onClick={FormOpenBTN}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {IsModelOpen && <CampusForm closeModel={closeModel}/>}
        </>
    )
}

export default Banner
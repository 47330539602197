import React,{useState} from 'react'
import EnrollForm from '../Sections/EnrollForm'

const LinuxJourney = () => {
    const [IsModelOpen, setIsModelOpen] = useState(false)

    const FormOpenBTN = () =>{
        setIsModelOpen(true)
    }

    const closeModel = ()=>{
        setIsModelOpen(false)
    }
  return (
    <>
        <section className='LinuxJourneySec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 mx-auto col-12'>
                        <div className='LinuxJourneyDiv'>
                            <h3>Embark on Your Learning Journey Today! Enroll Now in Our Online Course.</h3>
                            <button class="YellowBTN mt-4 m-auto d-flex justify-content-center" onClick={FormOpenBTN}>Enroll Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {IsModelOpen && <EnrollForm closeModel={closeModel}/>}
    </>
  )
}

export default LinuxJourney
import React from 'react';
import ReactPlayer from 'react-player'

const LinuxWhyChoose = () => {
    const Testimonial1 = "https://youtu.be/4KSdw0-amfE?si=ev1y3rqVzHnA_mA6";
  return (
    <section className='LinuxWhyChooseSec'>
        <div className='container'>
            <div className='row align-items-center gy-4'>
                <div className='col-lg-7 col-12'>
                    <div className='LinuxWhyChooseCon'>
                        <h3>Why Choose Us ?</h3>
                        <p>Choose our course for unmatched expertise and a thorough curriculum. Our sessions are thoughtfully crafted to deliver a transformative learning journey, seamlessly integrating theory with real-world application.</p>
                        <ul>
                            <li>
                                <h4>Unparalleled Expertise</h4>
                                <p>Will attain exceptional mastery.</p>
                            </li>
                            <li>
                                <h4>Comprehensive Curriculum</h4>
                                <p>In-depth and robust syllabus.</p>
                            </li>
                            <li>
                                <h4>Seasoned Instructors</h4>
                                <p>Exceptionally skilled trainers with unmatched proficiency.</p>
                            </li>
                            <li>
                                <h4>Cutting-Edge Resources</h4>
                                <p>Futuristic tools for boundless creativity.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-lg-5 col-12'>
                    <div className='VideoDiv'>
                        <ReactPlayer className="WhyVideiDiv" url={Testimonial1} controls={false} />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default LinuxWhyChoose
import React, { useRef, useState, useEffect } from 'react'
import Popupform from '../Components/Sections/Popupform'
import LinuxBanner from '../Components/LinuxSections/LinuxBanner'
import LinuxWhyChoose from '../Components/LinuxSections/LinuxWhyChoose'
import WhatWeOffer from '../Components/LinuxSections/WhatWeOffer'
import LinuxJourney from '../Components/LinuxSections/LinuxJourney'
import LinuxCta from '../Components/LinuxSections/LinuxCta'
import LinuxInstructor from '../Components/LinuxSections/LinuxInstructor'
import LinuxTestimonial from '../Components/LinuxSections/LinuxTestimonial'
import FreeCourse from '../Components/LinuxSections/FreeCourse'
import LinuxFAQ from '../Components/LinuxSections/LinuxFAQ'
import CourseDetail from '../Components/LinuxSections/CourseDetail'
import LinuxForm from '../Components/LinuxSections/LinuxForm'


const Linux = () => {

    const CourseRef = useRef(null)
    const scrollCourses = ()=>{
        const navbarheight = 90;
        const courseOffset = CourseRef.current.offsetTop - navbarheight
        // CourseRef.current.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
            top: courseOffset,
            behavior: 'smooth'
        })
    }

    const closeModel = ()=>{
        setShowPop(false)
    }
    const [showPop, setShowPop] = useState(false);

    useEffect(()=>{
        const timeOut = setTimeout(()=>{
            setShowPop(true)
        }, [5000])

        return () => clearTimeout(timeOut);
    },[])

    return (
        <>
            <LinuxBanner/>

            <LinuxWhyChoose/>
        
            <FreeCourse/>

            <WhatWeOffer/>

            <CourseDetail/>

            <LinuxJourney/>

            <LinuxCta/>

            <LinuxInstructor/>

            <LinuxTestimonial/>

            <LinuxFAQ/>

            <Popupform/>

            {/* <LinuxForm closeModel={closeModel}/> */}
            {showPop && <LinuxForm closeModel={closeModel}/>}
        </>
    )
}

export default Linux
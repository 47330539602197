import React, { useState, useEffect } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const BackendURL = process.env.REACT_APP_BACKEND_ADDRESS;

const Popupform = () => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_ADDRESS;
    // const BACKEND_URL = "http://localhost:5005"

    const [inpval, setInpval] = useState({
        fname: "",
        email: "",
        phone: "",
        course: ""
    })
    const setval = async (e)=>{

        const {name, value} = e.target

        setInpval(()=>{
            return{
                ...inpval,
                [name]: value
            }
        })
    }

    const userRegister = async (e)=>{
        console.log(BACKEND_URL)
        e.preventDefault();
        const {fname, email, number, course} = inpval;
        
        if(!fname || !email || !number || !course){
            toast.error("Please Fill All Fields")
        }else{
            try {
                const data = await fetch(`${BACKEND_URL}/registeruser`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        fname, email, number, course
                    })
                })

                const res = await data.json()
                console.log(res)

                if(res.status === 201){
                    toast.success("Successfully Register")
                    setInpval(()=>{
                        return{
                            ...inpval,
                            fname: "",
                            email: "",
                            number: "",
                            course: ""
                        }
                    })
                }
                if(res.status === 409){
                    toast.error("This Email allready register")
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const [showPop, setShowPop] = useState(false);

    // useEffect(()=>{
    //     const timeOut = setTimeout(()=>{
    //         setShowPop(true)
    //     }, [5000])

    //     return () => clearTimeout(timeOut);
    // },[])

  return (
    <>
        <div className={`PopUpForm ${showPop ? "PopUpShowForm" : ""}`}>
            <div className={`BannerFormDiv`}>
                <div className="modal-header">
                    <h4 className="modal-title" id="myModalLabel">
                        Request Callback
                    </h4>
                    <button
                        type="button"
                        className="close close-button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=>{setShowPop(false)}}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form className='mt-2'>
                    <div className='inputDiv'>
                        <label htmlFor="">Full Name</label>
                        <input type="text" onChange={setval} name='fname' value={inpval.fname} placeholder='Enter Your Name'/>
                    </div>
                    <div className='inputDiv'>
                        <label htmlFor="">Email</label>
                        <input type="email" onChange={setval} name='email' value={inpval.email} placeholder='Enter Your Email'/>
                    </div>
                    <div className='inputDiv'>
                        <label htmlFor="">Phone</label>
                        <input type="text" onChange={setval} name='number' value={inpval.number} placeholder='Enter Your Phone Number'/>
                    </div>
                    <div className="inputDiv SelectDiv">
                        <label htmlFor="">Courses</label>
                        <select onChange={setval} name='course' value={inpval.course} className='mb-0'  placeholder='Please Select Course'>
                            <option value="Null">Applying For</option>
                            <option value="Full Stack">Full Stack Web Development</option>
                            <option value="Machine Learning & AI">Machine Learning & AI</option>
                            <option value="DevOps">Cloud Engineering & DevOps</option>
                            <option value="IOT">Embedded Systems & IoT</option>
                        </select>
                    </div>
                    <button type="submit" className="YellowBTN" onClick={userRegister}>
                        Register
                    </button>
                </form>
            </div>
        </div>
        <ToastContainer />
    </>
  )
}

export default Popupform
import React, { forwardRef, useState } from "react";
import Courses1 from "../../images/Courses(1).png";
import Courses2 from "../../images/Courses(2).png";
import Courses3 from "../../images/Courses(3).png";
import Courses4 from "../../images/Courses(4).png";
import CurriculumForm from "./CurriculumForm";
import EnrollForm from "./EnrollForm";

const Coureses = forwardRef((props, ref) => {
  const [curriculum, setCurriculum] = useState({
    isOpen: false,
    courseId: null,
  });

  const openCurriculum = (courseId) => {
    setCurriculum({
      isOpen: true,
      courseId: courseId,
    });
  };

  const [IsModelOpen, setIsModelOpen] = useState(false);

  const FormOpenBTN = () => {
    // setIsModelOpen(true);
    const paymentSec = document.getElementById("register-section");
    paymentSec.scrollIntoView({ block: "end" });
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };

  return (
    <>
      <section ref={ref} className="CoursesSec" id="CourseID">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="Headings text-center">
                Our Summer Internship Courses
              </h2>
            </div>
            <div className="col-12">
              <div className="CoursesDiv">
                <ul>
                  <li>
                    <div className="icn">
                      <img src={Courses2} alt="course" />
                      <div className="con">DevOps & Cloud Computing</div>
                    </div>
                    <div className="conLI">
                      <ol>
                        <li>Duration - 45 days (6Weeks)</li>
                        <li>
                          Linux, AWS, Azure, Jenkins, Ansible and Terraform ,
                          Docker, Kubernetes
                        </li>
                      </ol>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        openCurriculum(1);
                      }}
                      className="curriculumBTN"
                    >
                      Download Curriculum
                    </button>
                  </li>
                  <li>
                    <div className="icn">
                      <img src={Courses3} alt="course" />
                      <div className="con">Data Science with ML & AI</div>
                    </div>

                    <div className="conLI">
                      <ol>
                        <li>Duration - 45 days (6Weeks)</li>
                        <li>
                          Python, Databases, Machine Learning, Deep Learning,
                          Computer Vision, Natural Language Processing
                        </li>
                      </ol>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        openCurriculum(2);
                      }}
                      className="curriculumBTN"
                    >
                      Download Curriculum
                    </button>
                  </li>
                  <li>
                    <div className="icn">
                      <img src={Courses4} alt="course" />
                      <div className="con">Full Stack Web Development</div>
                    </div>
                    <div className="conLI">
                      <ol>
                        <li>Duration - 45 days (6Weeks)</li>
                        <li>
                          Mern Complate, Web Development, Version Control, Web
                          Design
                        </li>
                      </ol>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        openCurriculum(3);
                      }}
                      className="curriculumBTN"
                    >
                      Download Curriculum
                    </button>
                  </li>
                  <li>
                    <div className="icn">
                      <img src={Courses1} alt="course" />
                      <div className="con">Embedded Systems & IoT</div>
                    </div>

                    <div className="conLI">
                      <ol>
                        <li>Duration - 45 days (6Weeks)</li>
                        <li>
                          Embedded Systems, IoT, In-depth knowledge of IoT,
                          Communication Protocols, GPIO
                        </li>
                      </ol>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        openCurriculum(4);
                      }}
                      className="curriculumBTN"
                    >
                      Download Curriculum
                    </button>
                  </li>
                </ul>
                <button
                  class="YellowBTN mt-4 m-auto d-flex justify-content-center"
                  onClick={FormOpenBTN}
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {curriculum.isOpen && (
        <CurriculumForm
          closeCurriculum={() => {
            setCurriculum({ isOpen: false, courseId: null });
          }}
          courseId={curriculum.courseId}
        />
      )}
      {/* {IsModelOpen && <EnrollForm closeModel={closeModel} />} */}
    </>
  );
});

export default Coureses;

import React from 'react';
import JumpCareer from '../../images/JumpCareer.jpg';
import JumpStart1 from '../../images/JumpStart_1.png';
import JumpStart2 from '../../images/JumpStart_2.png';
import JumpStart3 from '../../images/JumpStart_3.png';
import JumpStart4 from '../../images/JumpStart_4.png';

const JumpCareerCom = () => {
    return (
        <>
            <section className="JumpCareerSec">
                <div className="container">
                    <div className="row align-items-center">
                        <div class="col-lg-7 col-12">
                            <div className="jumpCon">
                                <h3>Jumpstart your engineering career with exclusive Industry Internship Program</h3>
                                <p>Gain hands on experience, industry connections & accerlate your professional growth</p>
                                <ul>
                                    <li>
                                        <img src={JumpStart1} alt="icon" />
                                        <h6>Learn from Industry Experts</h6>
                                    </li>
                                    <li>
                                        <img src={JumpStart2} alt="icon" />
                                        <h6>Globally accepted</h6>
                                    </li>
                                    <li>
                                        <img src={JumpStart3} alt="icon" />
                                        <h6>Over 25k+ Engineers Trained</h6>
                                    </li>
                                    <li>
                                        <img src={JumpStart4} alt="icon" />
                                        <h6> Offline and Online - Expert led sessions</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="jumpImg">
                                <img src={JumpCareer} alt="jump" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JumpCareerCom;
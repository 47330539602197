import React from 'react'
import './campusBanner.css'

const WhyApply = ({img, heading, para}) => {
  return (
    <>     
        <div className="ApplyCol">
          <div className="imgdiv">
            <img src={img} alt="apply img" />
          </div>
          <div className="WhyApplyDiv bg-dark text-center" >
              <h5 className=''>{heading}</h5>
              <p className='text-white'>{para}</p>
          </div>
        </div>
    </>
  )
}

export default WhyApply
import React from 'react';
import {Link} from 'react-router-dom'

const LinuxCta = () => {
  return (
    <>
        <section className='LinuxCtaSec'>
            <div className='container'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-lg-9 col-12'>
                        <div className='LinuxCTACon'>
                            <h3>Join Our WhatsApp Group Now</h3>
                            <p>Join our WhatsApp group to stay informed, exchange knowledge, and explore further insights in our shared pursuit of continuous growth and expertise.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-12'>
                        <Link to="/" className='ConnectBTN'>Connect with us</Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default LinuxCta
import React from "react";

const CourseDetail = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="text-center">
                <h2 className="headings">Linux Fundamentals of Cloud Computing</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="LinuxCourseCol">
                <ul>
                  <li>
                    <h6>Day 1</h6>
                    <ul>
                      <li>What is Linux</li>
                      <li>History and Philosophy of Linux</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 2</h6>
                    <ul>
                      <li>Key Features of Linux</li>
                      <li>Advantages of Using Linux</li>
                      <li>Comparing Linux to Other Operating Systems</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 3</h6>
                    <ul>
                      <li>Preparing for Installation</li>
                      <li>Hardware Requirements</li>
                      <li>Pre-installation Checks</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 4</h6>
                    <ul>
                      <li>Installing Linux Distributions</li>
                      <li>CentOS / Red Hat Installation</li>
                      <li>Ubuntu Installation</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 5</h6>
                    <ul>
                      <li>Virtualization and VirtualBox</li>
                      <li>Virtualization Concepts</li>
                      <li>Installing and Configuring VirtualBox</li>
                      <li>Creating Virtual Machines</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 6</h6>
                    <ul>
                      <li>Introduction to the Linux Terminal</li>
                      <li>Command-Line Interface (CLI) Basics</li>
                      <li>Navigating the Filesystem</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 7</h6>
                    <ul>
                      <li>Linux System Commands</li>
                      <li>Managing Processes</li>
                      <li>User and Group Management</li>
                      <li>System Information</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 8</h6>
                    <ul>
                      <li>System Utility Commands</li>
                      <li>Working with Dates and Times</li>
                      <li>Calculations with bc</li>
                      <li>Essential Commands (cal, which, uname, hostname)</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 9</h6>
                    <ul>
                      <li>Service Management with systemctl</li>
                      <li>Managing Services and Daemons</li>
                      <li>Process Management</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 10</h6>
                    <ul>
                      <li>Exploring the Linux Filesystem</li>
                      <li>Directory Structure</li>
                      <li>Filesystem Hierarchy Standard (FHS)</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 11</h6>
                    <ul>
                      <li>Manipulating Files and Directories</li>
                      <li>Creating, Deleting, and Renaming</li>
                      <li>Copying and Moving</li>
                      <li>Permissions and Ownership</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 12</h6>
                    <ul>
                      <li>File and Directory Command</li>
                      <li>Viewing File Contents (cat, less)</li>
                      <li>Searching for Files (find, locate)</li>
                      <li>File Permissions and Attributes (chmod, chown)</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 13</h6>
                    <ul>
                      <li>List Introduction to Shell Scripting</li>
                      <li>Scripting Basics</li>
                      <li>Shell Scripting vs. Programming Languages</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 14</h6>
                    <ul>
                      <li>Writing Your First Shell Script</li>
                      <li>Script Execution</li>
                      <li>Variables and Data Types</li>
                      <li>Conditional Statements and Loops</li>
                      <li>if, else, elif statements</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 15</h6>
                    <ul>
                      <li>for and while loops</li>
                      <li>Functions and Scripting Best Practices</li>
                      <li>Writing Reusable Code</li>
                      <li>Shell Scripting Best Practices </li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 16</h6>
                    <ul>
                      <li>Networking Basics</li>
                      <li>TCP/IP Fundamentals</li>
                      <li>Network Interfaces and Configuration</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 17</h6>
                    <ul>
                      <li>SSH and Remote Access</li>
                      <li>SSH Configuration</li>
                      <li>Securely Connecting to Remote Servers</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 18</h6>
                    <ul>
                      <li>DNS and DHCP</li>
                      <li>DNS Configuration</li>
                      <li>DHCP Setup</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 19</h6>
                    <ul>
                      <li>Web Server Installation and Configuration</li>
                      <li>Installing and Configuring Apache Web Server</li>
                      <li>Hosting a Simple Website</li>
                      <li>Web Server Security Best Practices </li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 20</h6>
                    <ul>
                      <li>Package Management Concepts</li>
                      <li>Package Managers Overview</li>
                      <li>Repositories and Package Sources</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 21</h6>
                    <ul>
                      <li>Managing Software with yum (CentOS/Red Hat)</li>
                      <li>Installing, Updating, and Removing Packages</li>
                      <li>Repository Configuration</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 22</h6>
                    <ul>
                      <li>Managing Software with apt (Ubuntu/Debian)</li>
                      <li>Package Management on Debian-based Systems</li>
                      <li>Managing Software Repositories</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 23</h6>
                    <ul>
                      <li>Advanced File Operations</li>
                      <li>File Linking and Symbolic Links</li>
                      <li>Archiving and Compression (tar, gzip, zip)</li>
                      <li>Filesystem Management with fstab</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 24</h6>
                    <ul>
                      <li>Understanding fstab and Mount Points</li>
                      <li>Managing Storage Devices</li>
                      <li>Disk Quotas</li>
                      
                    </ul>
                  </li>
                  <li>
                    <h6>Day 25</h6>
                    <ul>
                      <li>Implementing Disk Quotas</li>
                      <li>Monitoring and Managing Disk Usage</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 26</h6>
                    <ul>
                      <li>User and Group Management</li>
                      <li>Managing Users and Groups</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 27</h6>
                    <ul>
                      <li>User Account Security</li>
                      <li>File Permissions</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 28</h6>
                    <ul>
                      <li>Understanding File Permissions</li>
                      <li>Special Permissions (SUID, SGID, Sticky Bit)</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 29</h6>
                    <ul>
                      <li>Capstone Project One - System Monitoring Tool</li>
                    </ul>
                  </li>
                  <li>
                    <h6>Day 30</h6>
                    <ul>
                      <li>Capstone Project Two - Auto fs</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default CourseDetail;

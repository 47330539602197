import React from "react";
import CampusBanner from "../Components/CampusSections/CampusBanner";
import RewardSection from "../Components/CampusSections/rewardSection";
import TrainedStudents from "../Components/CampusSections/TrainedStudents";
import Apply from "../Components/CampusSections/Apply";
import CampusAmbassadorDuration from "../Components/CampusSections/CampusAmbassadorDuration";
import Whatyoudo from "../Components/CampusSections/Whatyoudo";
import Banner from "../Components/CampusSections/Banner";
import { Helmet } from "react-helmet";

const Campus = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Campus Ambassador Program: Join Our Team | Apply Now!</title>
        <meta
          name="description"
          content="Become a Campus Ambassador and gain leadership experience while representing our brand on your campus. Apply now to join our team, develop valuable skills, and make an impact in your community!"
        />
      </Helmet>
      <CampusBanner />
      <RewardSection />
      <TrainedStudents />
      <Apply />
      <CampusAmbassadorDuration />
      <Whatyoudo />
      <Banner />
    </>
  );
};

export default Campus;

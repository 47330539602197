import React from "react";

const LinuxFAQ = ({ FAQ }) => {
  return (
    <>
      <section className={`faq-section ${FAQ}`}>
        <div className="container">
          <div className="row">
            {/* ***** FAQ Start ***** */}
            <div className="col-md-6 offset-md-3">
              <div className="text-center">
                <h2 className="headings">FAQs</h2>
              </div>
            </div>
            <div className="col-12">
              <div className="faq" id="accordion">
                <div className="row">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample1"
                  >
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                            >
                              What platform you’ll be using for these classes?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            There will be live YouTube sessions where you can interact with the instructor and other students, ask questions, and participate in discussions.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwo"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwo"
                            >
                              Will there be any assessments or exams?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            There will be quizzes, assignments, and possibly a final project to assess your understanding of the material. Also, the website of the Upflairs will provide you the list of assessment and e-resources.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThree"
                              aria-expanded="false"
                              aria-controls="flush-collapseThree"
                            >
                              Will I receive a certificate upon completion of the program?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            Yes, upon successful completion of the program and meeting the requirements, you will receive a certificate of completion.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFour"
                              aria-expanded="false"
                              aria-controls="flush-collapseFour"
                            >
                              What will I learn in this 45-day Linux learning program?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFour"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            In this program, you will learn the fundamentals of Linux operating system, including command-line basics, file system navigation, user management, package management, shell scripting, and more.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do I need any prior knowledge or experience with Linux?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseFive"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            No prior knowledge or experience with Linux is required. This program is designed for beginners.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseSix"
                              aria-expanded="false"
                              aria-controls="flush-collapseSix"
                            >
                              How will the course be structured?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseSix"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            The course will consist of video lectures, hands-on exercises, quizzes, and assignments. You will have access to online resources and some assessment related to it.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseSeven"
                              aria-expanded="false"
                              aria-controls="flush-collapseSeven"
                            >
                              What resources will I need for this program?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseSeven"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            You will need a computer with internet access.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseEight"
                              aria-expanded="false"
                              aria-controls="flush-collapseEight"
                            >
                              Is this course free of cost?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseEight"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            The course registration is free for the initial 50 students, thereafter, a registration fee of 20 Rs per student will apply.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseNine"
                              aria-expanded="false"
                              aria-controls="flush-collapseNine"
                            >
                              Will I have access to course materials after the 45-day period is over?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseNine"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            Yes, you will have continued access to course materials in our Youtube channel allowing you to review content at your own pace.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTen"
                              aria-expanded="false"
                              aria-controls="flush-collapseTen"
                            >
                              How can I track my progress throughout the program?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTen"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            You will have access to progress tracking tools, including quizzes, assignments, and some hands on mini projects.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseEleven"
                              aria-expanded="false"
                              aria-controls="flush-collapseEleven"
                            >
                              Can I access the course materials and lectures at any time, or are there specific times when they will be available?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseEleven"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            Course materials will be accessible at any time, allowing students to study and review at their own pace. Live sessions may have scheduled times, but recordings will be available for later viewing.


                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseTwelve"
                              aria-expanded="false"
                              aria-controls="flush-collapseTwelve"
                            >
                              Will there be opportunities for one-on-one interactions with instructors or teaching assistants?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseTwelve"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            Yes, students may have the opportunity for one-on-one interactions with instructors or teaching assistants through office hours, email, or other communication channels.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqcard">
                          <h2 className="accordion-header faqHeading">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseThirteen"
                              aria-expanded="false"
                              aria-controls="flush-collapseThirteen"
                            >
                              Will the course videos remain available on the YouTube channel indefinitely, or will they be removed after a certain period?
                            </button>
                          </h2>
                          <div
                            id="flush-collapseThirteen"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample1"
                          >
                            <div className="accordion-body">
                            The course videos will remain available on the YouTube channel indefinitely, allowing you to watch them at any time.


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LinuxFAQ;

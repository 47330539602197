import React from 'react';
import Gallery1 from '../../images/Gallery(1).jpg';
import Gallery2 from '../../images/Gallery(2).jpg';
import Gallery3 from '../../images/Gallery(3).jpg';
// import Gallery4 from '../../images/Gallery(4).jpg';
// import Gallery5 from '../../images/Gallery(5).jpg';
// import Gallery6 from '../../images/Gallery(6).jpg';
import Gallery7 from '../../images/Gallery(7).jpg';
import Gallery8 from '../../images/Gallery(8).jpg';
// import Gallery9 from '../../images/Gallery(9).jpg';
import Gallery10 from '../../images/Gallery(10).jpg';
import Gallery11 from '../../images/Gallery(11).jpg';

const Gallery = () => {
    return (
        <>
            <section className="GallerySec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="Headings text-center text-white">
                                Glimpse of UPFLAIRS Training Internship Program
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="GalleryDiv">
                                <div className="container__img-holder">
                                    <img
                                        src={Gallery1}
                                        alt="gallery"
                                    />
                                </div>
                                <div className="container__img-holder FiftyIMG">
                                    <img
                                        src={Gallery2}
                                        alt="gallery"
                                    />
                                </div>
                                <div className="container__img-holder">
                                    <img
                                        src={Gallery3}
                                        alt="gallery"
                                    />
                                </div>
                                <div className="container__img-holder MarginTop">
                                    <img
                                        src={Gallery8}
                                        alt="gallery"
                                    />
                                </div>
                                <div className="container__img-holder">
                                    <img
                                        src={Gallery10}
                                        alt="gallery"
                                    />
                                </div>
                                <div className="container__img-holder">
                                    <img
                                        src={Gallery11}
                                        alt="gallery"
                                    />
                                </div>
                                <div className="container__img-holder MarginTop">
                                    <img
                                        src={Gallery7}
                                        alt="gallery"
                                    />
                                </div>
                            </div>
                            <div className="img-popup">
                                <img src="" alt="Popup" />
                                <div className="close-btn">
                                    <div className="bar" />
                                    <div className="bar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery
import React from 'react';
import CounterImg_2 from '../../images/CounterImg_2.png';
import CounterImg_3 from '../../images/CounterImg_3.png';
import CounterImg_4 from '../../images/CounterImg_4.png';
import CounterImg_5 from '../../images/CounterImg_5.png';
import CounterImg_6 from '../../images/CounterImg_6.png';


const CounterComp = () => {
    return (
        <>
            <section className="CounterSec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="CounSec">
                                <ul>
                                    <li>
                                        <div class="icn">
                                            <img src={CounterImg_2} alt="course" />
                                        </div>
                                        <div class="con">
                                            <h3>100+</h3>
                                            <p>Institution Tie-up</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icn">
                                            <img src={CounterImg_3} alt="course" />
                                        </div>
                                        <div class="con">
                                            <h3>25000+</h3>
                                            <p>Learners on board</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icn">
                                            <img src={CounterImg_4} alt="course" />
                                        </div>
                                        <div class="con">
                                            <h3>20+</h3>
                                            <p>Technologies</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icn">
                                            <img src={CounterImg_5} alt="course" />
                                        </div>
                                        <div class="con">
                                            <h3>15+</h3>
                                            <p>Industry Experts</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="icn">
                                            <img src={CounterImg_6} alt="course" />
                                        </div>
                                        <div class="con">
                                            <h3>1000+</h3>
                                            <p>Sessions Delivered</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CounterComp;
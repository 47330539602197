import React from 'react';

const CampusAmbassadorDuration = () => {
    return (
        <section className="campus-ambassador-duration">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 DurContent">
                        <h2 className="section-title">Embrace three months of transformative learning!</h2>
                        <p className="section-description">Join us for a thrilling Campus Ambassador experience spanning <span className='sectionImp'> April, May, </span> and <span className='sectionImp'>June</span>. Don't miss out on this exciting opportunity!</p>
                    </div>
                    <div className="col-lg-3 d-flex justify-content-center align-items-center">
                        <div className="month-boxes">
                            <div className="month-box april">April</div>
                            <div className="month-box may">May</div>
                            <div className="month-box june">June</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CampusAmbassadorDuration;

import React from 'react';
import Tesimonial1 from '../../images/Testimonial(1).jpg';
import Tesimonial2 from '../../images/Testimonial(2).jpeg';
import Tesimonial3 from '../../images/Testimonial(3).jpeg';
import Tesimonial4 from '../../images/Testimonial(4).jpeg';

const Testimonial = () => {
    return (
        <>
            <section className="TestimonialSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 class="headings text-center text-white">Impactful Feedback from Summer Internship Pioneers!</h2>
                        </div>
                    </div>
                    <div className="row MarginTop110">
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="TestiDiv">
                                <img src={Tesimonial3} alt="testimonial" />
                                <h5>Satyam Raj</h5>
                                <h6>NIT Mizoram</h6>
                                <p>It was such a great session. The way of presenting every thought and views of the teacher was great. Thanks for such an amazing session.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="TestiDiv">
                                <img src={Tesimonial1} alt="testimonial" />
                                <h5>Damini Srivastava</h5>
                                <h6>Banasthali Vidyapith</h6>
                                <p>The training was outstanding beyond remarks. We never expected to learn this much in just within 3 days. It was really an amazing session. Much motivated now to move on our python journey. Thank You Upflairs for developing within us the enthusiasm to learn more and more.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="TestiDiv">
                                <img src={Tesimonial2} alt="testimonial" />
                                <h5>Lalit Agarwal</h5>
                                <h6>Poornima College of Engineering</h6>
                                <p>Overall it was a great experience, as having knowledge on recent technologies used in industry will make us industry ready for the future. I am very grateful to Upflairs for guiding me so well with technologies. Thank you.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="TestiDiv">
                                <img src={Tesimonial4} alt="testimonial" />
                                <h5>Harshita Samant</h5>
                                <h6>Banasthali Vidyapith</h6>
                                <p>Thanks so much Upflairs for your valuable training. I really enjoyed it and appreciate that you made it fun. It strengthened my technical skills and proved to be a great learning experience. Your helpful attitude makes it clear that you can continue to take on challenges and grow my knowledge in that field.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial
import React from 'react';
import Collage1 from '../../images/collage(1).png';
import Collage2 from '../../images/collage(2).png';
import Collage3 from '../../images/collage(3).png';
import Collage4 from '../../images/collage(4).png';
import Collage5 from '../../images/collage(5).png';
import Collage6 from '../../images/collage(6).png';
import Collage7 from '../../images/collage(7).png';
import Collage8 from '../../images/collage(8).png';
import Collage9 from '../../images/collage(9).png';
import Collage10 from '../../images/collage(10).png';
import Collage11 from '../../images/collage(11).png';
import Collage12 from '../../images/collage(12).png';
import Collage13 from '../../images/collage(13).png';
import Collage14 from '../../images/collage(14).png';
import Collage15 from '../../images/collage(15).png';
import Collage16 from '../../images/collage(16).png';
import Collage17 from '../../images/collage(17).png';
import Collage18 from '../../images/collage(18).png';
import Collage19 from '../../images/collage(19).png';
import Collage20 from '../../images/collage(20).png';
import Collage21 from '../../images/collage(21).png';

const TrustedCollages = () => {
    return (
        <>
            <section className="TrusCollSec">
                <div className="container-fluid">
                    <div className="row">
                        <div class="col-12">
                            <h2 class="Headings text-center text-white">Trusted By Top Universities & Colleges</h2>
                            <p class="SubHeading text-center text-white">Our team has successfully delivered trainings to over 15K+ students in almost 350+ Colleges across the globe </p>
                        </div>
                        <div className="col-12">
                            <div className="owl-carousel TrustedCollageCarousel owl-theme">
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage1} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage2} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage3} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage4} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage5} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage6} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage7} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage8} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage9} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage10} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage11} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage12} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage13} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage14} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage15} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage16} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage17} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage18} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage19} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage20} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={Collage21} alt="collage" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TrustedCollages
import React, { useState } from 'react';
import WhatWeOfferIMG from '../../images/WhatWeOffer.png';
import mypdf from '../../images/curriculum-Devops.pdf';
import { Link } from 'react-router-dom';
import CurriculumForm from '../Sections/CurriculumForm';

const WhatWeOffer = ({props}) => {

    const [curriculum, setCurriculum] = useState({
        isOpen: false,
        courseId: null
    })

    const openCurriculum = (courseId)=>{
        setCurriculum({
            isOpen: true,
            courseId: courseId
        })
        console.log("Clickedddd")
    }

  return (
    <>
    <section className='LinuxWhyChooseSec LinuxWhatWeOffer'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-lg-5 col-12'>
                    <div className='IMGDiv'>
                        <img src={WhatWeOfferIMG} alt='image div'/>
                    </div>
                </div>
                <div className='col-lg-7 col-12'>
                    <div className='LinuxWhyChooseCon'>
                        <h5>Know Your Course</h5>
                        <h3>Discover Your Course Potential , Upflairs Empowers Skill Enhancement.</h3>
                        <p>Immerse Yourself in the Open-Source Realm with a Versatile 45-Day Linux Course, Providing Hands-On Experience, Community Support, and a Solid Grounding for Continued Learning. Discover Further Options Below.</p>
                        <ul>
                            <li>
                                <button type='button' onClick={()=>{openCurriculum(5)}} >Download Syllabus</button>
                            </li>
                            <li>
                                <Link to="https://upflairs.com/">Go to YouTube Playlist</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {curriculum.isOpen && <CurriculumForm closeCurriculum={()=>{setCurriculum({isOpen: false, courseId: null})}}courseId={curriculum.courseId}/>}
    </>
    )
}

export default WhatWeOffer
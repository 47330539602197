import React from 'react'
import Card from './Card'
import img from './img/CampusDothree.jpg'
import img2 from './img/CampusDotwo.jpg'
import img3 from './img/CampusDoone.jpg'
import img4 from './img/CampusDofour.jpg'
const Whatyoudo = () => {
  return (
    <>
         <section className='WhatToDo'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center WhatHead">
                        <h4 className='text-uppercase'>What will you do?</h4>
                    </div>
                    <div className="col-12 d-flex justify-content-lg-between align-content-between">
                        <div className="row gy-4">
                            <div className="col-xl-3 col-lg-6 col-12">
                                <Card CardImg={img4} customClass={"CardCss"} heading={"Brainstrom"} para={" Embark on a journey of imagination, ignite the flames of creativity, and cultivate innovative strategies to amplify Upflairs' influence throughout your campus community."}/>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12">
                                <Card CardImg={img3} customClass={"CardCss1"} heading={"Spreadhead"} para={"Take the helm as an ambassador for Upflairs, championing our Summer Internship program and offerings within your campus community, fostering growth and opportunity."}/>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12">
                                <Card CardImg={img2} customClass={"CardCss2"} heading={"Social Media Manager"} para={"Take charge of spreading Upflairs' message by sharing eye-catching e-posters and forwarding emails, embodying our brand within your college community."}/>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-12">
                                <Card CardImg={img} customClass={"CardCss3"} heading={"Connect"} para={"Forge profound and enduring connections, adeptly steering students towards our prestigious Summer Internship Opportunity, fostering reciprocal growth."}/>
                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        </section>
    </>
  )
}

export default Whatyoudo
import React from 'react';
import CompanyLogo1 from '../../images/CompanyLogo_1.png';
import CompanyLogo2 from '../../images/CompanyLogo_2.png';
import CompanyLogo3 from '../../images/CompanyLogo_3.png';
import CompanyLogo4 from '../../images/CompanyLogo_4.png';
import CompanyLogo5 from '../../images/CompanyLogo_5.png';
import CompanyLogo6 from '../../images/CompanyLogo_6.png';
import CompanyLogo7 from '../../images/CompanyLogo_7.png';
import CompanyLogo8 from '../../images/CompanyLogo_8.png';
import CompanyLogo9 from '../../images/CompanyLogo_9.png';
import CompanyLogo10 from '../../images/CompanyLogo_10.png';
import CompanyLogo11 from '../../images/CompanyLogo_11.svg';
import CompanyLogo12 from '../../images/CompanyLogo_12.png';
import CompanyLogo13 from '../../images/CompanyLogo_13.png';
import CompanyLogo14 from '../../images/CompanyLogo_14.png';
import CompanyLogo15 from '../../images/CompanyLogo_15.png';
import CompanyLogo16 from '../../images/CompanyLogo_16.png';
import CompanyLogo17 from '../../images/CompanyLogo_17.png';
import CompanyLogo18 from '../../images/CompanyLogo_18.png';
import CompanyLogo19 from '../../images/CompanyLogo_19.png';

const LookOurWork = () => {
    return (
        <>
            <section className="TrusCollSec LookOutWork">
                <div className="container-fluid">
                    <div className="row">
                        <div class="col-12">
                            <p class="SubHeading text-center text-white">Look where Our Alumnis work at</p>
                        </div>
                        <div className="col-12">
                            <div className="owl-carousel LookOutWorkCarousel owl-theme">
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo1} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo2} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo3} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo4} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo5} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo6} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo7} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo8} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo9} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo10} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo11} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo12} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo13} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo14} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo15} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo16} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo17} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo18} alt="collage" />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="CollageImg">
                                        <img src={CompanyLogo19} alt="collage" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LookOurWork
import React from 'react'
import './campusBanner.css'
import { IoPersonSharp } from "react-icons/io5";
import { FaGraduationCap } from "react-icons/fa6";
const TrainedStudents = () => {
  return (
    <>
      <section className='TrainedStudentsSec'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 mb-3 d-flex justify-content-center AboutText">
              <h3>We are always expanding to excellence</h3>
            </div>
          </div>
          <div className="row gy-3 AboutDiv">
            <div className=" col-lg-6 col-12 d-flex align-items-lg-center justify-content-lg-center">
              <div className="TrainedStudItem d-flex">
                <IoPersonSharp className='icon1' />
                <h4 className='ColText px-3'>500+ Ambassadors</h4>
              </div>
            </div>
            <div className=" col-lg-6 col-12 d-flex align-items-lg-center justify-content-lg-center">
              <div className="TrainedStudItem d-flex">
                <FaGraduationCap className='icon2' />
                <h4 className='ColText px-3'>500+ Institutions</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TrainedStudents
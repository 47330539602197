import React from "react";
import { NavLink } from "react-router-dom";
import { IoIosCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import upflairsLogo from "../images/upflairs-logo-blue.png";

const PaymentSuccess = () => {
  return (
    <div className="px-[2rem] gap-[3rem] payment-success-page">
      <img src={upflairsLogo} className="success-up-logo" />
      <div className="flex items-center justify-center gap-[5rem]">
        <img src="/payment-success.png" alt="" className="hidden xl:block" />
        <div className="payment-success-msg-div">
          <h1 className="payment-success-msg-1">
            Your payment was successfull. Internship Letter will be send within
            5 minutes to the email address you have mentioned.
          </h1>
          <h1 className="payment-success-msg-1">
            If you still didn't recieve the email, please check the spam section
            or contact us.
          </h1>
          <h3 className="payment-success-msg-2 ">
            <IoIosCall className="w-[2rem] h-[2rem]" />{" "}
            <a href="tel:6350417917">6350417917</a>
          </h3>
          <h3 className="payment-success-msg-2">
            <IoMdMail className="w-[2rem] h-[2rem]" />{" "}
            <a href="mailto:hr@upflairs.com">hr@upflairs.com</a>
          </h3>
          <NavLink className="homepage-link" to="/">
            Go Back to Homepage
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

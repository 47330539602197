import React from 'react'

const ExploreCourses = ({scrollCourses}) => {
    return (
        <>
            <section className="ExpCourSec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="ExploreCouCon text-center">
                                <h4>Embark on the Knowledge Odyssey: Fuse Learning and Experience to Ignite Your Journey!</h4>
                                <button class="YellowBTN mt-4" onClick={scrollCourses}>Explore Courses</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExploreCourses;
import React, { useRef, useState } from "react";
import RegisterForm from "./RegisterForm";
import { NavLink } from "react-router-dom";
import BannerMain from "../../images/MainBannerIMG.png";
import BannerMainDesktop from "../../images/MainBannerDesktop.png";
import BannerMain2 from "../../images/MainBannerIMG2.png";
import BannerMainDesktop2 from "../../images/MainBannerDesktop2.png";
import EnrollForm from "../Sections/EnrollForm";

const Banner = ({ scrollCourses }) => {
  const [IsModelOpen, setIsModelOpen] = useState(false);

  const FormOpenBTN = () => {
    // setIsModelOpen(true);
    const paymentSec = document.getElementById("register-section");
    paymentSec.scrollIntoView({ block: "end" });
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };

  return (
    <>
      {/* Banner */}
      <section className="MainBanner" onClick={FormOpenBTN}>
        {/* <img src={BannerMainDesktop} alt='Banner' className='BannerImg DesktopIMG'/>
                <img src={BannerMain} alt='Banner' className='BannerImg MobileIMG'/> */}
        {/* <div className="container">
                    <div className="row align-items-center gy-5">
                        <div className="col-md-6 col-12">
                            <div className="BannerCon">
                                <h3><b>Soar into Success this Summer</b>
                                    Elevate Your Career with Our Job-Ready Summer Internship & Training Program 2024!</h3>
                                <button className="YellowBTN mt-4" onClick={scrollCourses}>Know More</button>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <RegisterForm/>
                        </div>
                    </div>
                </div> */}
        <div
          id="carouselExampleInterval"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval={2000}>
              <img
                src={BannerMainDesktop}
                alt="Banner"
                className="BannerImg DesktopIMG"
              />
              <img
                src={BannerMain}
                alt="Banner"
                className="BannerImg MobileIMG"
              />
            </div>
            <div className="carousel-item" data-bs-interval={2000}>
              <img
                src={BannerMainDesktop2}
                alt="Banner"
                className="BannerImg DesktopIMG"
              />
              <img
                src={BannerMain2}
                alt="Banner"
                className="BannerImg MobileIMG"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      {/* {IsModelOpen && <EnrollForm closeModel={closeModel} />} */}
    </>
  );
};

export default Banner;

import React from 'react';
import {Link} from 'react-router-dom'

const FreeCourse = () => {
  return (
    <>
        <section className='LinuxCtaSec FreeCourseSec'>
            <div className='container'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-lg-9 mx-auto col-12'>
                        <div className='LinuxCTACon text-center'>
                            <h3><span className='AnimateText'>FREE</span> Explore Linux: A Complete Online Course - Absolutely FREE!</h3>
                            <p>At Upflairs, enjoy a top-tier learning journey completely free of charge. Dive into our expert-led curriculum, engage in hands-on labs, collaborate with a vibrant community, and apply your skills to real-world scenarios. Earn certification, tap into cutting-edge resources, and foster a commitment to lifelong learning, all at zero cost to you.</p>
                            <Link to="/" className='ConnectBTN'>View Course</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default FreeCourse